import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { DAHero } from "../components/digital-assets/da-hero"
import { DAAbout } from "../components/digital-assets/da-about"
import { DAOurTeam } from "../components/digital-assets/da-our-team"
import { DAGlobalFootprint } from "../components/digital-assets/da-global-footprint"
import { DAOurPartners } from "../components/digital-assets/da-our-partners"
import { DAWorkTogether } from "../components/digital-assets/da-work-together"
import { DAOffices } from "../components/digital-assets/da-offices"
import { DAContact } from "../components/digital-assets/da-contact"
import { processHexagonData } from "../helpers/common"
import BHDigitalModal from "../components/bh-digital-modal"
import BHVideosModal from "../components/bh-videos-modal"
import useModalProps from "../helpers/use-modal-props"

import { IframeMain } from "../styles/iframe.styles"
import { SectionTitle } from "../elements/section-title"
import { HexagonTitle } from "../elements/hexagon-title"
import { HexagonTitleMain } from "../styles/common.styles"
import "../styles/bh-videos/bh-videos.style.css"

// Import Swiper React components
// import '/node_modules/swiper/css';
import "swiper/swiper.css"
// import 'swiper/css/pagination';
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

const dataVideos = [
  {
    introduction: [
      {
        id: 0,
        src: "https://player.vimeo.com/video/833656626?h=a25c79a09d",
        title: "Introducing BH Digital - November 2021",
      },
      {
        id: 1,
        src: "https://player.vimeo.com/video/833659906?h=88073071ff",
        title: "Digital Asset Ecosystem - February 2022",
      },
    ],
    crypto_bytes: [
      {
        id: 2,
        src: "https://player.vimeo.com/video/833663515?h=348922e770",
        title: "An Introduction with Colleen and Peter - June 2023",
      },
    ],
    focus_series: [
      {
        id: 4,
        src: "https://player.vimeo.com/video/833664673?h=d95a633400",
        title: "Regulatory Landscape with Andrew Nelson - June 2023",
      },
      {
        id: 3,
        src: "https://player.vimeo.com/video/833667130?h=807f2ebf7a",
        title: "Counterparty Risk with Ivan Chen - March 2023",
      },
    ],
  },
]

var initialOpen = true
// const [initialOpen, setInitialOpen] = React.useState(null);

if (typeof window !== "undefined") {
  if (
    window.localStorage.length == 0 ||
    window.localStorage.getItem("bh-disclosureNoticesStatus") !== "accepted"
  ) {
    // console.log('do we have this value?', window.localStorage, (window.localStorage.getItem('bh-disclosureNoticesStatus') !== 'accepted'), window.localStorage.length == 0);
    // setInitialOpen(false);
    initialOpen = false
  }
}

/* function setInitialOpen(value){
  initialOpen = value;
} */

const StandardVideoPageTemplate = ({
  data: {
    wpgraphql: {
      page: { title, videosstructureacf, digitaldisclaimersacf },
    },
  },
}) => {
  // console.log('checking passed swiper', my_swiper.slideNext());
  // const { modalProps } = useModalProps({ initialOpen: typeof window !== 'undefined' ? (window.localStorage.getItem('bh-disclosureNoticesStatus') !== 'accepted' ? false : true) : false });
  const { modalProps } = useModalProps({ initialOpen: initialOpen })
  const { modalStatus } = modalProps
  // console.log(newvideosacf,'Quick fix')

  // const swiper = useSwiper();
  // const [my_swiper, set_my_swiper] = null;

  const [swiper, setSwiper] = React.useState(null)
  const [swiperv2, setSecondSwiper] = React.useState(null)

  // var swiper = React.useState(null);

  /*   videosstructureacf.standardVideoSection.forEach(function(item, index){
    // console.log('creating dynamic variabls', index);
    eval('const '+['swiper'+index] + '= ' + React.useState(null) + ';');
    console.log('creating dynamic variabls', ('swiper'+index));
  }) */

  const nexto = () => {
    swiper.slideNext()
  }
  const prevto = () => {
    swiper.slidePrev()
  }

  const nextov2 = () => {
    swiperv2.slideNext()
  }
  const prevtov2 = () => {
    swiperv2.slidePrev()
  }

  function swiperto(item) {
    alert(item)
    // swiper.slideTo(item);
  }

  const x = (x, y) => {
    return x * y
  }

  return (
    <Layout
      disableAgreementModal={true}
      onAccept={() => {
        modalProps?.openModal({})
      }}
      modalStateOverride={!modalStatus?.open}
    >
      <SEO title={title} />

      <div className="video-slider-wrapper v-intro-bg">
        <div className="vs-container-wrap">
          <div className="container">
            <h3 className="txt-white">{title}</h3>
          </div>
        </div>
      </div>

      {videosstructureacf.standardVideoSection.map((section, index) => (
        <div
          className={"video-slider-wrapper " + section.sectionClassnames}
          key={"vw-" + index}
        >
          <div className="vs-container-wrap">
            <div className="container">
              <h3 className="">{section.sectionHeading}</h3>
              <p className="s-sub-heading txt-center">
                {section.sectionDescription}
              </p>

              {section.videos.length > 1 && (
                <div>
                  <div id="swiper-series" className="swiper-container">
                    <Swiper
                      // modules={[Navigation, Pagination]}
                      spaceBetween={50}
                      slidesPerView={2}
                      centeredSlides={true}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={swiper => {
                        console.log("checking swiper slider", swiper)
                        // my_swiper = swiper;
                        setSwiper(swiper)
                      }}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                        850: {
                          slidesPerView: 2,
                        },
                      }}
                    >
                      {section.videos.map((video, vindex) => (
                        <SwiperSlide key={"ser-" + vindex}>
                          <p className="txt-center">
                            <em>{video.description}</em>
                          </p>
                          <div className="iframe-container">
                            <iframe
                              className="vimeo-iframe responsive-iframe"
                              src={video.videoLink}
                              width="740"
                              height="460"
                              frameBorder="0"
                              allow="autoplay; fullscreen; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="v-slide-nav">
                    <button onClick={prevto}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7.882"
                        height="14.622"
                        viewBox="0 0 7.882 14.622"
                      >
                        <path
                          id="Path_73795"
                          data-name="Path 73795"
                          d="M0,0,6.794,6.794C6.443,6.443,13.589,0,13.589,0"
                          transform="translate(7.325 0.53) rotate(90)"
                          fill="none"
                          stroke="#bbb4a9"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </button>
                    <div className="vs-pagination">
                      {section.videos.map((pagi2, index) => (
                        <span
                          key={"nav-series-" + index}
                          onClick={() => swiper.slideTo(index)}
                        ></span>
                      ))}
                    </div>
                    <button onClick={nexto}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7.882"
                        height="14.622"
                        viewBox="0 0 7.882 14.622"
                      >
                        <path
                          id="Path_68716"
                          data-name="Path 68716"
                          d="M0,6.795,6.795,0C6.443.352,13.59,6.795,13.59,6.795"
                          transform="translate(7.352 0.53) rotate(90)"
                          fill="none"
                          stroke="#bbb4a9"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}

              {section.videos.length == 1 && (
                <div>
                  <p className="txt-white txt-center">
                    <em>{section.videos[0].description}</em>
                  </p>
                  <div className="single-video-container">
                    <div className="iframe-container">
                      <iframe
                        className="vimeo-iframe responsive-iframe"
                        src={section.videos[0].videoLink}
                        width="740"
                        height="460"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}

      <BHVideosModal
        modalProps={modalProps}
        digitalassetsacf={digitaldisclaimersacf}
      />
    </Layout>
  )
}

export default StandardVideoPageTemplate

export const svTemplateQuery = graphql`
  query standardVideosPagesQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        videosstructureacf {
          standardVideoSection {
            sectionHeading
            sectionDescription
            sectionClassnames
            videos {
              description
              videoLink
            }
          }
        }
        digitaldisclaimersacf {
          modalFooterText
          modalCloseButtonLabel
          modalAcceptButtonLabel
          disclosuresModalData {
            title
            content {
              text
              title
            }
          }
          riskFactorsModalData {
            title
            content {
              text
              title
            }
          }
        }
      }
    }
  }
`
